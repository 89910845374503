import '../assets/sass/main.scss';

import PropTypes from 'prop-types';
import React from 'react';

import Seo from './Seo';

const Layout = ({ children, location }) => {
  let content;

  if (location && location.pathname === '/') {
    content = <div>{children}</div>;
  } else {
    content = (
      <div id="wrapper" className="page">
        {children}
      </div>
    );
  }

  return (
    <>
      <Seo />
      {content}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
