import { withPrefix } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';

import logo from '../assets/img/logo.png';
import useSiteMetadata from '../hooks/use-site-metadata';

const Seo = (props) => {
  const { url, title, subtitle, description } = useSiteMetadata();

  const logoUrl = url + withPrefix(logo);

  return (
    <Helmet>
      <html lang="gl" />
      <title>{`${title} | ${subtitle}`}</title>
      <meta name="description" content={description} />
      <meta property="og:site_name" content={title} />
      <meta property="og:image" content={logoUrl} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={logoUrl} />
    </Helmet>
  );
};

Seo.propTypes = {};

export default Seo;
